.h1 {
  font-size: var(--font-size-xxlg);
  line-height: 1.1;
  font-weight: 700;

  @media (min-width: 21rem) {
    font-size: var(--font-size-xxxlg);
    line-height: 1.2;
  }
}

.h2 {
  font-size: var(--font-size-xlg);
  font-weight: 700;
  line-height: 1.2;

  @media (min-width: 21rem) {
    font-size: var(--font-size-xxlg);
  }
}

.h3 {
  font-size: var(--font-size-lg);
  font-weight: 700;
}

.h4 {
  font-size: var(--font-size-md);
  font-weight: 600;
}

.h5 {
  font-size: var(--font-size-sm);
  font-weight: 700;
  line-height: 1.25;
}

.h6 {
  font-size: var(--font-size-xxs);
  font-weight: 800;
  text-transform: uppercase;
}

.h1,
.h2,
.h3 {
  color: white;
  margin-bottom: var(--gutter-md);
  text-align: center;
}

.h4,
.h5,
.h6 {
  color: var(--neutral-darker);
}

.primaryTheme {
  color: var(--primary-midtone);
}

.neturalTheme {
  color: var(--neutral-darker);
}

.whiteTheme {
  color: #fff;
}
