:root {
  --text-shadow: 0 0.063rem 0 rgb(5 49 73 / 75%),
    0 0.25rem 0.75rem rgb(5 49 73 / 50%), 0 0.125rem 0.25rem rgb(9 66 98 / 25%);

  --neutral-lightest: #f7f7f7;
  --neutral-lighter: #eee;
  --neutral-light: #ccc;
  --neutral-midtone: #999;
  --neutral-dark: #777;
  --neutral-darker: #555;
  --neutral-darkest: #333;

  --primary-lightest: #e2f6ff;
  --primary-lighter: #00ace2;
  --primary-light: #007abe;
  --primary-midtone: #105880;
  --primary-dark: #105880;
  --primary-darker: #105880;

  --secondary-lightest: #f6efff;
  --secondary-lighter: #7b61ff;
  --secondary-light: #d186ff;
  --secondary-midtone: #a036e0;
  --secondary-dark: #7d38a8;
  --secondary-darker: #612785;

  --accent-lighter: #e2c9fb;
  --accent-midtone: #a036e0;

  --see-lightest: #ffefc6;
  --see-lighter: #ffc85d;
  --see-light: #f8981f;
  --see-midtone: #e86f00;
  --see-dark: #c75300;
  --see-darker: #b72c00;

  --see-bg-gradient: linear-gradient(
    180deg,
    rgb(248 152 31) 0%,
    rgb(232 111 0) 100%
  );
  --see-bg-gradient-disabled: linear-gradient(
    180deg,
    rgb(248 152 31 / 50%) 0%,
    rgb(232 111 0 / 50%) 100%
  );

  --think-lightest: #d3f0f5;
  --think-lighter: #71daed;
  --think-light: #09b8d7;
  --think-midtone: #0ea2c3;
  --think-dark: #0087a6;
  --think-darker: #006a82;

  --think-bg-gradient: linear-gradient(
    180deg,
    rgb(9 184 215) 0%,
    rgb(14 162 196) 100%
  );
  --think-bg-gradient-disabled: linear-gradient(
    180deg,
    rgb(9 184 215 / 50%) 0%,
    rgb(14 162 196 / 50%) 100%
  );

  --feel-lightest: #ffe5ec;
  --feel-lighter: #ffacc0;
  --feel-light: #ff527c;
  --feel-midtone: #ee2a5a;
  --feel-dark: #cb1945;
  --feel-darker: #ab173b;

  --feel-bg-gradient: linear-gradient(
    180deg,
    rgb(255 82 124) 0%,
    rgb(238 42 90) 100%
  );
  --feel-bg-gradient-disabled: linear-gradient(
    180deg,
    rgb(255 82 124 / 50%) 0%,
    rgb(238 42 90 / 50%) 100%
  );

  --act-lightest: #dff2c1;
  --act-lighter: #c6e867;
  --act-light: #8cc640;
  --act-midtone: #67a500;
  --act-dark: #2d8b00;
  --act-darker: #236c00;

  --act-bg-gradient: linear-gradient(
    180deg,
    rgb(140 198 64) 0%,
    rgb(101 163 0) 100%
  );
  --act-bg-gradient-disabled: linear-gradient(
    180deg,
    rgb(140 198 64 / 50%) 0%,
    rgb(101 163 0 / 50%) 100%
  );

  --secondary-bg-gradient: linear-gradient(
    180deg,
    rgb(209 134 255) 0%,
    rgb(159 54 224) 53.65%,
    rgb(97 39 133) 100%
  );

  --secondary-bg-gradient-disabled: linear-gradient(
    180deg,
    rgb(209 134 255) 0%,
    rgb(159 54 224) 53.65%,
    rgb(97 39 133) 100%
  );

  --see-button-box-shadow: 0 0.125rem 0.125rem rgb(183 44 0 / 15%),
    0 0.125rem 0.5rem rgb(183 44 0 / 25%);
  --think-button-box-shadow: 0 0.125rem 0.125rem rgb(0 106 130 / 15%),
    0 0.125rem 0.5rem rgb(0 106 130 / 25%);
  --feel-button-box-shadow: 0 0.125rem 0.125rem rgb(171 23 59 / 25%),
    0 0.125rem 0.5rem rgb(171 23 59 / 25%);
  --act-button-box-shadow: 0 0.125rem 0.125rem rgb(35 108 0 / 15%),
    0 0.125rem 0.5rem rgb(35 108 0 / 25%);

  --button-box-shadow: 0 0.125rem 0.125rem rgb(5 49 73 / 15%),
    0 0.125rem 0.5rem rgb(5 49 73 / 25%);
  --button-focus-box-shadow: 0 0 0 0.25rem var(--primary-lighter);
  --button-hover-box-shadow: 0 0 0 0.125rem #fff, var(--button-box-shadow);

  --button-highlight-focus-box-shadow: 0 0 0 0.25rem var(--secondary-lighter);

  --select-box-shadow: 0 0.125rem 0.25rem rgb(0 98 130 / 10%),
    0 0.375rem 0.75rem rgb(0 98 130 / 25%);

  --navbar-box-shadow: 0 0.063rem 0.25rem rgba(0 98 130 / 10%),
    0 0.125rem 0.5rem rgba(0 98 130 / 10%);

  --modal-box-shadow: 0 0.125rem 0.25rem rgb(0 98 130 / 10%),
    0 0.25rem 0.75rem rgb(0 98 130 / 25%);

  --range-thumb-box-shadow: 0 0.063rem 0.25rem rgb(16 88 128 / 40%),
    0 0.063rem 0.5rem rgb(16 88 128 / 20%);

  --bubble-box-shadow: 0 0.063rem 0.25rem rgb(0 98 130 / 10%),
    0 0.063rem 0.5rem rgb(0 98 130 / 25%);

  --thumbskill-default-shadow: 0 0.125rem 0.25rem rgb(0 98 130 / 10%),
    0 0.25rem 0.75rem rgb(0 98 130 / 25%);

  --thumbskill-active-shadow: 0 0.125rem 0.25rem rgb(0 98 130 / 15%),
    0 0.125rem 0.75rem rgb(0 98 130 / 25%);

  --thumbskill-hover-shadow: 0 0.25rem 0.5rem rgb(0 98 130 / 25%),
    0 0.25rem 0.75rem rgb(0 98 130 / 35%);

  --thumbskill-focus-shadow: var(--thumbskill-hover-shadow);

  --white-xs-box-shadow: 0 0 0 0.125rem #fff;
  --white-sm-box-shadow: 0 0 0 0.25rem #fff;

  --feedback-box-shadow: 0 0.125rem 0.5rem rgb(0 98 130 / 10%),
    0 0.063rem 0.125rem rgba(0 98 130 / 25%);

  --feedback-positive-lightest: #effff1;
  --feedback-positive-lighter: #def8e0;
  --feedback-positive-light: #b6e9bb;
  --feedback-positive-midtone: #1cbd2c;
  --feedback-positive: #2f6d4c;

  --feedback-warning-lightest: #fffce4;
  --feedback-warning-lighter: #ffefc6;
  --feedback-warning-light: #ffd977;
  --feedback-warning-midtone: #ffb21c;
  --feedback-warning-dark: #b66226;
  --feedback-warning: #b72c00;

  --feedback-negative-lightest: #fff5f7;
  --feedback-negative-light: #ffc7d1;
  --feedback-negative-lighter: #dc001a;
  --feedback-negative-midtone: #97212f;
  --feedback-negative: #71262f;

  --neutral-light-box-shadow: 0 0 0 0.125rem var(--neutral-light) inset;
  --neutral-midtone-box-shadow: 0 0 0 0.125rem var(--neutral-midtone) inset;
  --primary-lighter-box-shadow: 0 0 0 0.125rem var(--primary-lighter) inset;
  --primary-dark-box-shadow: 0 0 0 0.125rem var(--primary-dark) inset;
  --secondary-lighter-box-shadow: 0 0 0 0.125rem var(--secondary-lighter) inset;

  --disabled-box-shadow: 0 0 0 0.125rem #d5d5d5 inset;
  --error-box-shadow: 0 0 0 0.125rem #dc001a inset;

  --primary-background-gradient: linear-gradient(
    180deg,
    #0070a0 0%,
    #0090be 71.87%,
    #0194c3 97.4%
  );

  --border-radius-xxxs: 0.125rem; // 2px
  --border-radius-xxs: 0.25rem; // 4px
  --border-radius-xs: 0.5rem; // 8px
  --border-radius-sm: 0.625rem; // 10px
  --border-radius-md: 0.75rem; // 12px
  --border-radius-lg: 1.125rem; // 18px
  --border-radius-xlg: 1.25rem; // 20px
  --border-radius-xxlg: 1.375rem; // 22px
  --border-radius-xxxlg: 2rem; // 32px
  --border-radius-xxxxlg: 3rem; // 48px

  --input-border-radius: var(--border-radius-sm);
  --select-border: solid 0.1875rem var(--neutral-light);
  --select-border-focus: solid 0.1875rem var(--primary-lighter);
  --select-border-active: solid 0.1875rem var(--primary-light);

  --font-size-xxs: 0.75rem; // 12px;
  --font-size-xs: 0.875rem; // 14px;
  --font-size-sm: 1rem; // 16px;
  --font-size-md: 1.25rem; // 20px;
  --font-size-lg: 1.5rem; // 24px;
  --font-size-xlg: 1.75rem; // 28px;
  --font-size-xxlg: 2rem; // 32px;
  --font-size-xxxlg: 2.5rem; // 40px;

  --gutter-xxxxs: 0.125rem; // 2px
  --gutter-xxxs: 0.25rem; // 4px
  --gutter-xxs: 0.5rem; // 8px
  --gutter-xs: 0.75rem; // 12px
  --gutter-sm: 1rem; // 16px
  --gutter-md: 1.25rem; // 20px
  --gutter-lg: 2.5rem; // 40px
  --gutter-xlg: 3.75rem; // 60px
  --gutter-xxlg: 4.375rem; // 70px
  --gutter-xxxlg: 6.25rem; // 100px

  // Breakpoints for reference
  --screen-xxs-min: 21rem; // 336px;
  --screen-xs-min: 30rem; // 480px;
  --screen-sm-min: 48rem; // 768px;
  --screen-smlg-min: 54rem; // 868px;
  --screen-md-min: 60rem; // 960px;
  --screen-mdlg-min: 64rem; // 1024px;
  --screen-lg-min: 75rem; // 1200px;
  --screen-xlg-min: 86rem; // 1380px;
}
