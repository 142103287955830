@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700;800&display=optional');

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: Nunito, sans-serif;
  font-size: 16px;

  /* stylelint-disable-next-line selector-class-pattern */
  > div:not(.ReactModalPortal) {
    height: 100%;

    > div {
      height: 100%;
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

p {
  font-size: 1rem;
  line-height: 1.25;
  color: var(--neutral-darker);
}

/* stylelint-disable-next-line selector-class-pattern */
.ReactModal__Overlay--after-open {
  z-index: 1000;
}

/* stylelint-disable-next-line selector-class-pattern */
.ReactModal__Body--open {
  overflow: hidden;
}
