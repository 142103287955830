.container {
  height: 100vh;
  overflow: hidden;
}

.isReverse {
  height: 100%;
}

.isReverse .main {
  height: 100%;
  background: unset;
}

.isReverse .main .text {
  color: var(--primary-dark);
}

.header {
  padding: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #105880 0%, #0070a0 100%);

  @media (min-width: 48rem) {
    padding: var(--gutter-md);
  }
}

.imageContainer {
  width: 4rem;
  height: 2.25rem;
  position: relative;

  @media (min-width: 48rem) {
    width: 7.5rem;
    height: 3.75rem;
  }
}

.main {
  height: calc(100vh - 8rem);
  background: var(--primary-background-gradient);
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 48rem) {
    height: calc(100vh - 17.5rem);
  }
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner {
  width: 2.5rem;
  height: 2.5rem;
  animation: spin 2s linear infinite;
}

.text {
  margin-top: var(--gutter-xs);
  font-size: var(--font-size-xxs);
  line-height: 1;
  letter-spacing: 0.063rem;
  animation: pulse 2s linear infinite;
}

.footer {
  width: 60rem;
  position: relative;
  height: 4rem;
  background: url('/images/cloud--mobile.jpg') no-repeat;
  background-size: 60rem 4rem;
  animation: slide-xs 4s linear infinite;

  @media (min-width: 30rem) {
    width: 143.75rem;
    background-size: 143.75rem 4rem;
    animation: slide-md 6s linear infinite;
  }

  @media (min-width: 48rem) {
    width: 262.5rem;
    height: 11.25rem;
    background: url('/images/cloud--desktop.jpg') no-repeat;
    background-size: 262.5rem 11.25rem;
    animation: slide-lg 12s linear infinite;
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes slide-xs {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-20rem);
  }
}

@keyframes slide-md {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-48rem);
  }
}

@keyframes slide-lg {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-87.5rem);
  }
}
