.icon {
  width: 1.25rem;
  height: 1.25rem;
}

.light {
  path {
    fill: #fff;
  }
}

.xs {
  width: 0.75rem;
  height: 0.75rem;
}

.sm {
  width: 1rem;
  height: 1rem;
}

.md {
  width: 1.25rem;
  height: 1.25rem;
}

.lg {
  width: 1.5rem;
  height: 1.5rem;
}

.xl {
  width: 1.75rem;
  height: 1.75rem;
}
